import styles from './header.module.scss';
// eslint-disable-next-line
import React from 'react';

export type Props = {
    modalOpened?: boolean;
}

const Header = ({modalOpened}: Props) => {
    return (
        <>
            <div className={styles['header']} data-modal-opened={modalOpened}>
                <div className={styles['link-container']}>
                    <a className="links" href="https://beyonce.com/">HOME</a>
                    <a className="links" href="https://shop.beyonce.com/">SHOP</a>
                    <a className="links" href="https://parfum.beyonce.com/">PARFUM</a>
                    <a className="links" href="https://cecred.com/">CÉCRED</a>
                </div>
            </div>
        </>
    )
}

export default Header;
