import React from 'react';
import styles from './App.module.scss';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import MusicContent from './components/music-content/music-content';
import { useState } from 'react';
import CleanMusicContent from './components/music-content/clean-music-content';
import SongModal from './components/song-modal/song-modal';
import { fullCredits } from './components/music-content/credits.model';


function App() {
  const [currVersion, setCurrVersion] = useState('clean');
  const [modalState, setModalState] = useState(false);
  const [openCredits, setOpenCredits] = useState(false);

  const setCurrentVersion = (vers: string) => {
    setCurrVersion(vers);
  }

  const gettingModalState = (currState: boolean) => {
    setModalState(currState);
  }

  const openCreditsFunc = () => {
      setOpenCredits(true)
  }

  const closeCredits = () => {
    if(openCredits) {
      setOpenCredits(false);
    } else {
      setOpenCredits(true)
    }
  }

  return (
    <div className={styles['App']} data-modal-opened={modalState}>
        <Header
          modalOpened={modalState}
        />
        <Footer />
        <div className={styles['version-select']} data-modal-opened={modalState}>
          <p data-is-active={currVersion === 'clean'} onClick={() => setCurrentVersion('clean')}>Clean</p>
          <p>/</p>
          <p  data-is-active={currVersion === 'explicit'} onClick={() => setCurrentVersion('explicit')}>Explicit</p>
        </div>
        {!modalState && (
          <div className={styles['full-credits-button']}>
            <p onClick={() => openCreditsFunc()}>Credits</p>
          </div>
        )}

        {currVersion === 'clean' && (
          <CleanMusicContent
            modalState={gettingModalState} />
        )}

        {currVersion === 'explicit' && (
          <MusicContent
            modalState={gettingModalState}
          />
        )}

        {openCredits && (
          <SongModal 
              isOpen={openCredits} 
              context={'full-credits'} 
              updateContext={closeCredits} 
              closeModal={closeCredits} 
              fullCredits={fullCredits.credits}
          />
        )}
    </div>
  );
}

export default App;
