import styles from './footer.module.scss';
// import { useMediaQuery } from 'react-responsive'

// const legal = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum'

const Footer = () => {
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })

    return (
        <>
           <div id="shopify-section-footer" className={styles['shopify-section-footer']}>
                <div className={styles['column-container']}>  
                    <div className={styles['footer-column']}>    
                        <div className={styles['w-full input-group flex flex-row']}>
                            <a href="https://beyonce.attn.tv/p/Q9J/landing-page" className={styles['uppercase']}  title="Subscribe for updates.">JOIN</a>
                        </div>   
                    </div>  
                    <div className={styles['footer-column']} id="copyright">    
                        <div>&copy;&nbsp;2024&nbsp;BEYONC&Eacute;
                        </div>  
                    </div>  
                    <div className={styles['footer-column']}>           
                        <a href="https://www.beyonce.com/terms-conditions/" className={styles['terms']} title="Terms">TERMS</a>           
                        <a href="https://www.beyonce.com/privacy-policy/" className={styles['']} title="Terms">PRIVACY</a>
                    </div>
                </div>
                {/* <p className={styles['left-item']}>act i</p>
                {isBigScreen ? (
                    <>
                        <p className={styles['legal']}>
                             <span>© 2022 BEYONCÉ</span><br></br><br></br>
                            <span><a href="https://www.beyonce.com/privacy-policy/">Privacy Policy</a> | <a href="https://www.beyonce.com/terms-conditions/">Terms and Conditions</a></span>
                        </p>
                        <p className={styles['right-item']}>RENAISSANCE</p>
                    </>
                ) : (
                    <>
                        <p className={styles['center-item']}>RENAISSANCE</p>
                            <div className={styles['mobile-links']}>
                                <a href="https://www.beyonce.com/privacy-policy/">
                                    <p>
                                        Privacy Policy
                                    </p>
                                </a>
                                <a href="https://www.beyonce.com/terms-conditions/">
                                    <p>
                                        Terms
                                    </p>
                                </a>
                            </div>
                    </>
                )} */}
                
            </div>
        </>
    )
}

export default Footer;
